import env from '@polleverywhere/core/lib/env'
import User from '@polleverywhere/core/lib/models/user'
import RegistrationInfo from '@polleverywhere/core/lib/models/registration_info'
import Activity from '@polleverywhere/toolkit-response/lib/activity'
import { notify, clearAll } from './notification_manager'
import activityFactory from '@polleverywhere/graphql-models/lib/activityFactory'
import { cutlassEvent } from '@polleverywhere/cutlass'

function withinMsTeamsTab () {
  return /Teams/.test(navigator.userAgent)
}

function withinZoomApp () {
  return /ZoomApps/.test(navigator.userAgent)
}

function responseSource (model, isPinned, isStatic) {
  if (withinMsTeamsTab()) {
    return 'ms_teams_participant_app'
  } else if (withinZoomApp()) {
    return 'zoom_participant_app'
  } else if (isPinned) {
    return 'pinned_activity'
  } else if (isStatic && model.type() === 'survey') {
    return model.get('is_full_page') ? 'one_page_survey' : 'multi_page_survey'
  } else if (isStatic) {
    return 'private_link'
  } else if (model.type() === 'survey') {
    return (model.get('is_full_page') ? 'activated_one_page_survey' : 'activated_multi_page_survey')
  } else {
    return 'pollev_page'
  }
}

const showMessage = function (element, message) {
  const hide = (message.indexOf('@') > -1) && env.ios() && window.Capacitor?.isNative

  if (!hide) {
    notify(element, {
      title: 'Response saved',
      content: message,
      type: 'success'
    })
  }
}

const showError = function (element, message) {
  // TODO: Send to rollbar?
  notify(element, {
    title: 'Error',
    content: message,
    type: 'error'
  })
}

/**
 * Ensure competition leaderboard knows the participant ID so it can generate
 * the correct screenname
 * This is a workaround and updates the references leaderboard model within the activity
 */
function getLeaderboard (model, participantId) {
  let leaderboard
  if (model.type() === 'competition') {
    leaderboard = model.leaderboard
  } else if (model.competition) {
    leaderboard = model.competition.leaderboard
  }

  if (leaderboard && !leaderboard.options.participantId) {
    leaderboard.options.participantId = participantId
  }
  return leaderboard
}

function stopLiveUpdate (model) {
  if (model.type() === 'discourse') {
    model.stopLiveUpdate()
  } else if (model.type() === 'survey' && model.polls) {
    model.instance.stop()
    model.polls.each((poll) => poll.instance.stop())
  } else if (model.instance) {
    model.instance.stop()
  }
}

function startLiveUpdate (model) {
  if (model.type() === 'discourse') {
    model.liveUpdate()
  } else if (model.type() === 'survey' && model.polls) {
    model.instance.start()
    model.polls.each((poll) => poll.instance.start())
  } else {
    model.instance.start()
  }
}

/**
 * Create activity response view and all necessary listeners.
 */
function createLegacyResponseView (data) {
  if (!data.activity) return

  const activityModel = activityFactory({ type: data.activity.type, participant_key: data.activity.participant_key })
  activityModel.set(activityModel.parse(data.activity))

  const userModel = new User(data.user)
  const registrationInfoModel = new RegistrationInfo(data.registrationInfo)

  startLiveUpdate(activityModel)
  if (activityModel.type() === 'discourse') {
    // Initial fetch for discourse to get results and votes
    activityModel.fetch({ data: { exclude: 'flash_options,visual_settings' } })
  }

  const view = new Activity({
    model: activityModel,
    user: userModel,
    live: false, // We've already made it live
    source: responseSource(activityModel, data.isPinned, data.isStatic),
    registrationInfo: registrationInfoModel,
    leaderboard: getLeaderboard(activityModel, data.participant.id)
  })

  view.on('message', (message) => showMessage(view.el, message))
  view.on('error', (error) => showError(view.el, error))
  view.on('progress', () => {
    // Clear notifications in a survey when we progress to next question
    clearAll(view.el)
  })

  // Fire off any bubbled up cutlass events into the app.
  view.on('view:action', (event, data, opts) => {
    cutlassEvent(this, event, data)
  })

  view.on('result', (results) => {
    const event = new CustomEvent('result', {
      bubbles: true,
      detail: { results: Array.isArray(results) ? results : [results] }
    })

    view.el.dispatchEvent(event)
  })

  activityModel.on('change:registered_participants_only', (_model, needsRegistration) => {
    if (needsRegistration && !registrationInfoModel.isExistingParticipant()) {
      console.log('Redirecting to registration page')
      window.location = data.registerPath
    }
  })

  view.render()

  return view
}

export default class LegacyResponseView extends HTMLElement {
  static observedAttributes = ['data']

  disconnectedCallback () {
    this.cleanup()
  }

  cleanup () {
    try {
      if (this.responseView) {
        stopLiveUpdate(this.responseView.model)
        this.responseView.remove()
        this.responseView = null
      }
    } catch (e) {
      window?.Rollbar.warning('Error tearing down response view', e)
    }
  }

  // { activity: {...}, user: {...}, participant: {...}, registrationInfo: {...}, isPinned: Boolean, isStatic: Boolean }
  setup (data) {
    this.cleanup()
    this.responseView = createLegacyResponseView(data)
    this.appendChild(this.responseView.el)
  }
}

customElements.define('legacy-response-view', LegacyResponseView)
