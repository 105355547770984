// Note this file needs to be imported _first_ in the pack so that the
// env is fully setup _before_ our graphql library is loaded by the
// response components. As they pickup their env 'on import' rather
// than 'when called'.

import '../foreman_env.js.erb'

PollEv.env.set('proxy_enabled', true)
PollEv.env.set('nginx_proxy_path', '/proxy')
PollEv.env.set('graphql_url', '/proxy/graphql')
