require("@polleverywhere/toolkit-lib/lib/foreman_env.js.erb")
PollEv.env.set({
  
  slides_server_url: 'PLACEHOLDER_SLIDES_SERVER_URL_PLACEHOLDER',
  

  

  
  google_tag_manager_id: 'PLACEHOLDER_GOOGLE_TAG_MANAGER_ID_PLACEHOLDER',
  

  
  participant_embed_url: 'PLACEHOLDER_PARTICIPANT_EMBED_URL_PLACEHOLDER',
  

  
  viz_embed_url: "PLACEHOLDER_EMBED_URL_PLACEHOLDER",
  

  
  folder_nesting_limit: "PLACEHOLDER_FOLDER_NESTING_LIMIT_PLACEHOLDER",
  

  
  typekit_id: "duc0ksg",
  

  
  singularity_url: "PLACEHOLDER_SINGULARITY_URL_PLACEHOLDER",
  

  
  oidc_hostname: "id.eng.pe",
  

  
  graphql_url: "PLACEHOLDER_GRAPHQL_URL_PLACEHOLDER",
  

  
  api_server_url: "PLACEHOLDER_API_SERVER_URL_PLACEHOLDER",
  

  
  __no_comma: true
})
