import { partial } from 'underscore'
import { html, render } from 'lit-html'
import { ifDefined } from 'lit-html/directives/if-defined'
import { register } from '@polleverywhere/hmr-custom-element'

import '@polleverywhere/pe-notification'

/**
 * Hash of event types this manager will listen for from the nested DOM tree to
 * let consumers bubble up events and dispatch tasks for the manager to perform.
 */
const Events = {
  notify: 'notification:show',
  clearAll: 'notification:clearAll'
}

const TypeRank = [ // Ascending order of priority among notification types
  'info',
  'success',
  'warning',
  'error'
]

function handleAction (index, e) {
  const notification = this.notifications[index]

  if (typeof notification.onAction !== 'function') {
    throw new Error('onAction handler must be a function')
  }

  if (typeof notification.onDismiss !== 'function') {
    throw new Error('onDismiss handler must be a function')
  }

  switch (e.type) {
    case 'click:action':
      // If return value of callback is `true`, also remove the notification.
      notification.onAction() && delete this.notifications[index]
      break

    case 'click:dismiss':
      notification.onDismiss()
      delete this.notifications[index]
      break
  }

  renderTemplate.call(this)
}

function notificationSort (a, b) {
  if (b.type !== a.type) {
    return TypeRank.indexOf(b.type) - TypeRank.indexOf(a.type)
  } else {
    // We want the later notification to come first in sort order
    return b.triggerTime - a.triggerTime
  }
}

function handleEvent (e) {
  switch (e.type) {
    case Events.notify:
      this.notifications.push(Object.assign({}, e.detail, {
        triggerTime: Date.now()
      }))
      this.notifications.sort(notificationSort)

      // We only care about showing one notification at this time
      this.notifications.length = 1
      break

    case Events.clearAll:
      this.notifications.length = 0
      break
  }

  renderTemplate.call(this)
}

function renderTemplate () {
  if (!this.isConnected) { return }

  const template = this.notifications.map((item, index) =>
    html`<pe-notification
      type=${item.type}
      title=${item.title}
      content=${ifDefined(item.content)}
      action=${ifDefined(item.action)}
      @click:dismiss=${partial(handleAction, index)}
      @click:action=${partial(handleAction, index)}>
    </pe-notification>`)

  render(template, this, {
    eventContext: this
  })
}

export function clearAll (element) {
  const event = new CustomEvent(Events.clearAll, {
    bubbles: true,
    detail: {}
  })
  element.dispatchEvent(event)
}

export function notify (element, options = {}) {
  const {
    type,
    title,
    content,
    action,
    onAction = () => {},
    onDismiss = () => {}
  } = options

  const event = new CustomEvent(Events.notify, {
    bubbles: true,
    detail: { type, title, content, action, onAction, onDismiss }
  })
  element.dispatchEvent(event)
}

/**
 * Notification manager is an application-level element used to listen for
 * special events at the root application level to allow nested components to
 * dispatch events for the manager to show or clear notifications.
 *
 * By default, registers listeners to its own parent element as the primary
 * channel for notification events to be received. For example, given the
 * current DOM structure:
 *
 * <body>
 *   <div id="application">
 *     <notification-manager></notification-manager>
 *     ...
 *   </div>
 * </body>
 *
 * The "application" container listens for notification events. Any child
 * elements of this container may then dispatch notification events and have
 * them processed.
 *
 * @element notification-manager
 *
 * @prop {Array} notifications - Holds a list of serialized notification card
 *  options, used to render `pe-notification` elements into the DOM.
 */
export default class NotificationManager extends HTMLElement {
  static get tagName () { return 'notification-manager' }

  get notifications () {
    this._notifications = this._notifications || []
    return this._notifications
  }

  /**
   * Private callback to handle events bubbled up by nested elements and let us
   * remove the listener when disconnected.
   * @type {Function}
   */
  get _handleEvent () {
    this.__handleEvent = this.__handleEvent || handleEvent.bind(this)
    return this.__handleEvent
  }

  disconnectedCallback () {
    Object.values(Events).forEach((eventType) => {
      this.parentElement?.removeEventListener(eventType, this._handleEvent)
    })
  }

  connectedCallback () {
    Object.values(Events).forEach((eventType) => {
      this.parentElement?.addEventListener(eventType, this._handleEvent)
    })
  }

  attributeChangedCallback () {
    renderTemplate.call(this)
  }
}

register(module, NotificationManager.tagName, NotificationManager)
